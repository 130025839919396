<template>
  <div class="record">
    <div class="record-content">
      <div class="record-title is-flex is-flex-align-center is-flex-space-between">
        <span class="has-text-weight-bold">{{ scholarship ? item.title[language] : item.title }}</span>
        <span class="has-margin-left is-meta">{{ item.publish_date | formatDate }}</span>
      </div>
      <div
        v-if="item.description"
        class="has-margin-bottom-small has-text-small"
        v-html="scholarship ? item.description[language] : item.description"
      ></div>
      <div v-if="item.files.length && !scholarship">
        <div v-for="file in item.files" :key="file.$index">
          <a target="_blank" :href="file.url">Download {{ file.name }}</a>
        </div>
      </div>

      <div v-if="item.files.length && scholarship">
        <div v-for="file in item.files" :key="file.$index">
          <a target="_blank" :href="file.url.replaceAll('{KEY}', file.key).replaceAll('{FILENAME}', file.filename)"
            >Download {{ file.filename }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    scholarship: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    language() {
      return this.$store.state.language || this.$i18n.locale
    },
  },
}
</script>
