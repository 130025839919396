<template>
  <MainLayout
    v-if="entry"
    :back-to="backToUrl"
    :title="entry.type === 'scholarships' ? entry.prefix + entry.code : entry.code"
    :short-title="entry.code"
  >
    <template v-slot:content>
      <ContentLoader v-if="!entry" />
      <div v-else class="box has-margin-top">
        <div>
          <p class="is-size-3 has-text-weight-bold has-margin-bottom-small">
            {{ $t(`procedure.type.${entry.category_type}`) }}
          </p>
          <p class="is-size-1 has-text-weight-bold">{{ getProcedureTitle() }}</p>
          <p v-if="entry.area || entry.sub_area" class="is-size-4 has-margin-bottom has-margin-top">
            Área disciplinar de {{ entry.area }}{{ entry.sub_area ? ',  subárea de ' + entry.sub_area : '' }}.
          </p>
          <p v-if="entry.department_title">{{ entry.department_title }}</p>
          <p class="has-text-weight-bold">{{ entry.faculty_title }}</p>
          <div v-if="entry.type === 'scholarships' && language === 'pt'" class="has-margin-top-small">
            Concurso para atribuição de
            <b
              >{{ entry.number_of_seats }}
              {{ entry.number_of_seats === 1 ? 'bolsa de investigação' : 'bolsas de investigação' }}</b
            >, no âmbito do projeto <b>{{ entry.scholarship_project.name[language] }}</b
            >.
          </div>
          <div v-else-if="entry.type === 'scholarships' && language === 'en'">
            Call for
            <b
              >{{ entry.number_of_seats }}
              {{ entry.number_of_seats === 1 ? 'research fellowship' : 'research fellowships' }}</b
            >.
          </div>
        </div>
      </div>

      <div class="has-text-centered has-margin-bottom-large">
        <div v-if="!willStartSoon">
          <div v-if="getEndDayKey() != 'ended'" class="has-margin-top-medium">
            <div class="has-text-weight-bold">{{ $t('application.deadlines') }}</div>
            <div>
              <span
                v-if="entry.status == 'published' && getEndDayKey() == 'today'"
                class="is-size-2 has-text-weight-bold has-text-danger"
                >{{ $t('applicationState.endsToday') }}</span
              >
              <span
                v-if="entry.status == 'published' && getEndDayKey() == 'tomorrow'"
                class="is-size-2 has-text-weight-bold"
                >{{ $t('applicationState.endsTomorrow') }}</span
              >
              <span v-if="entry.status == 'published' && getEndDayKey() == 'other' && entry.publish_end">
                {{ $t('applicationState.endsIn') }} {{ entry.publish_end | formatDate }}
              </span>
            </div>
          </div>
          <div v-else>
            <div v-if="getEndDayKey() == 'other'" class="is-meta">
              {{ $t('applicationState.closedIn') }} {{ entry.publish_end }}
            </div>
            <div v-else-if="getEndDayKey() == 'ended'" class="has-margin-top">
              {{ $t('applicationState.closedIn') }} {{ entry.publish_end }}
            </div>
          </div>
          <div v-if="entry.application && entry.application.status" class="has-margin">
            <b-button
              v-if="entry.application.status"
              type="is-black"
              @click="$router.push('/application/' + entry.code + '/view')"
            >
              <faicon icon="cube"></faicon> {{ $t('applicationState.start') }}</b-button
            >
          </div>
          <div v-else-if="getEndDayKey() != 'ended'" class="has-margin">
            <b-button type="is-primary" size="is-medium" @click="startApplication">{{
              $t('applicationState.start')
            }}</b-button>
          </div>
        </div>
        <div v-if="willStartSoon" class="has-margin-top-medium">
          <div class="has-text-weight-bold has-margin-bottom-small">Candidaturas abertas em breve</div>
          <span class="is-size-3 tag is-black">A partir de {{ entry.publish_start }}</span>
        </div>
      </div>
      <div v-if="entry.updates && entry.updates.length" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>{{ $t('procedure.updates') }}</h2>
          </div>
        </div>
        <div class="panel-body">
          <RecordProcedureUpdate
            v-for="update in entry.updates"
            :key="update.title"
            :item="update"
            :scholarship="applicationProfile == 'scholarships'"
          />
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import dayjs from 'dayjs'

import ProcedureService from '@/services/ProcedureService'
import UtilitiesService from '@/services/UtilitiesService'

import MainLayout from '@/components/layouts/MainLayout'
import ContentLoader from '@/components/utilities/ContentLoader'
import RecordProcedureUpdate from '@/components/records/RecordProcedureUpdate'

export default {
  components: {
    MainLayout,
    ContentLoader,
    RecordProcedureUpdate,
  },
  data() {
    return {
      loading: false,
      entry: null,
      procedureTypes: [],
    }
  },
  computed: {
    language() {
      return this.$store.state.language || this.$i18n.locale
    },
    applicationProfile() {
      for (let values of Object.values(this.procedureTypes)) {
        for (let [key, value] of Object.entries(values)) {
          if (this.$route.params.code.startsWith(key)) {
            return value
          }
        }
      }
      return 'teachers'
    },
    organization() {
      return this.$route.query.org
    },
    backToUrl() {
      if (this.organization) {
        return '/explore/' + this.entry.type + '?org=' + this.organization
      }

      return '/explore/' + this.entry.type
    },
    willStartSoon() {
      const today = dayjs().format('YYYY-MM-DD')
      const publishedDR = dayjs(String(this.entry.published_dr_date)).format('YYYY-MM-DD')
      const publishStart = dayjs(String(this.entry.publish_start)).format('YYYY-MM-DD')
      return Boolean(publishedDR && publishStart && publishedDR <= publishStart && publishStart > today)
    },
    isOpen() {
      const today = dayjs().format('YYYY-MM-DD')
      const publishStart = dayjs(String(this.entry.publish_start)).format('YYYY-MM-DD')
      const publishEnd = dayjs(String(this.entry.publish_end)).format('YYYY-MM-DD')
      return Boolean(publishStart <= today && publishEnd >= today)
    },
  },
  async created() {
    this.loading = true
    await this.getProcedureTypes()
    await this.getProcedure()
  },
  methods: {
    async getProcedureTypes() {
      this.procedureTypes = await UtilitiesService.getProcedureTypes()
    },
    getEndDayKey() {
      const today = dayjs().format('YYYY-MM-DD')
      const tomorrow = dayjs(today)
        .add(1, 'days')
        .format('YYYY-MM-DD')
      const end = dayjs(String(this.entry.publish_end)).format('YYYY-MM-DD')

      if (end == today) {
        return 'today'
      } else if (end == tomorrow) {
        return 'tomorrow'
      } else if (end < today) {
        return 'ended'
      } else {
        return 'other'
      }
    },

    getProcedureTitle() {
      return ProcedureService.helpers.getProcedureTitle(this.entry)
    },
    async getProcedure() {
      this.loading = true

      if (this.applicationProfile == 'scholarships') {
        this.entry = await ProcedureService.getApply2Procedure(this.$route.params.code)
      } else {
        this.entry = await ProcedureService.get(this.$route.params.code)
      }

      this.loading = false
    },
    startApplication() {
      if (this.applicationProfile == 'scholarships') {
        window
          .open(process.env.VUE_APP_APPLY_2_BASE_URI + '/procedure/' + this.entry.prefix + this.entry.code, '_blank')
          .focus()
      } else {
        this.$buefy.dialog.confirm({
          title: this.$t('applicationState.start'),
          message: this.$t('applicationState.startConfirm') + this.entry.code + '?',
          confirmText: this.$t('actions.start'),
          type: 'is-primary',
          onConfirm: async () => {
            this.$router.push('/application/' + this.entry.code + '/start')
          },
        })
      }
    },
  },
}
</script>
